$(function() {
    var mao = $("#" + getParam("m")); //获得锚点
    if (mao.length > 0) {//判断对象是否存在

        var pos = mao.offset().top;
        $('html,body').animate({
            scrollTop:pos-30
        },1000);
        return false;
    }
    shareClick($("#line-share"));
});
function getParam(pname) {

    var params = location.search.substr(1); //  获取参数 平且去掉？
    var ArrParam = params.split('&');
    if (ArrParam.length == 1) {
        //只有一个参数的情况
        return params.split('=')[1];
    }
    else {
        //多个参数参数的情况
        for (var i = 0; i < ArrParam.length; i++) {
            if (ArrParam[i].split('=')[0] == pname) {

                return ArrParam[i].split('=')[1];
            }
        }
    }

}
var $p = {
    stimo: undefined,
    id: function(id) {
        return document.getElementById(id)
    }
}
function alterCustomFixedMsg(msg, percent,tim) {
    if(msg!==""){

        percent= (percent == undefined) ?50:percent;
        clearInterval($p.stimo);

        $("#arrorbox").length > 0 ? $("#arrorbox").html(msg) : $("body").append("<div id='arrorbox' style='background:rgba(0,0,0,0.7); position:fixed; border-radius:5px; line-height:20px; text-align:center; color:#FFF; font-size:20px; padding:10px; max-width:300px;box-shadow:0px 0px 7px rgba(0,0,0,0.5); z-index:99999;opacity:0;-webkit-transition:opacity 200ms ease-in-out;word-wrap:break-word;'>" + msg + "</div>");
        $("#arrorbox").css({
            "margin-left": Math.floor(($(window).width()-$("#arrorbox").outerWidth()) / 2),
            "bottom":  percent + "%",
            "opacity":1
        });
        $p.stimo = (tim == undefined) ? setTimeout("$('#arrorbox').remove()", 3000) : setTimeout("$('#arrorbox').remove()", tim);
    }
}

function getMetaContentByName(name) {
    return (document.getElementsByName(name)[0] || 0).content;
}
function  getValue(ele,attr) {
  var val = ele.attr('data-' + attr)
  if (!val) return
  // facebook hashtag
  if (attr === 'hashtag') {
    if (!val.startsWith('#')) {
      val = '#' + val
    }
  }
  return val
}
function shareClick(ele) {
  // console.log(ele);
  var url = getValue(ele,"url");

  var title = getMetaContentByName('title') || getMetaContentByName('Title') || document.title;
  var lineInfo = encodeURIComponent();
  var lineUrl = 'http://line.me/R/msg/text/?text='+title+encodeURIComponent(' ')+'url='+encodeURIComponent(url);
  ele.attr("href",lineUrl)
  // console.log(url,title,lineUrl);

}